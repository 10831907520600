/* global Backbone:false */

/**
 * View for the Open Enrollment countdown banners.
 */

import template from './enrollment-banner-t.hbs';
const userChannel = Backbone.Radio.channel('user');

import config from '../../config/config';
import moment from 'moment-timezone';

const EnrollmentBannerView = Backbone.Marionette.ItemView.extend({

    countdowns: [], // setInterval handles for countdowns so they can be cleared

    template: template,

    ui: {
        comingSoon  : '#comingSoonCountdown',
        nowOpen     : '#nowOpenCountdown'
    },

    initialize () {
        /*
         *  TO UPDATE THE OPEN ENROLLMENT BANNERS FOR FUTURE YEARS:
         *  Update the following lines with the new start and end dates and
         *  text label values.
         */
        this.model = new Backbone.Model({
            comingSoonStart : '2025-01-29T00:00:00',
            comingSoonEnd   : '2025-02-11T23:59:59',
            nowOpenStart    : '2025-02-12T00:00:00',
            nowOpenEnd      : '2025-02-26T23:59:59',
            enrollmentYear  : '2025',
            startDayText    : 'Feb. 12',
            endDayText      : 'Feb. 26'
        });

        this.model.set('userCanSeeCountdowns',
            (userChannel.request('hasCapability', 'Benefits_Retail_GeneralAgent') ||
            userChannel.request('hasCapability', 'Benefits_Retail_GeneralAgent(disabled)') ||
            userChannel.request('hasCapability', 'Benefits_Retail')));
        this.model.set('userIsGA',
            (userChannel.request('hasCapability', 'Benefits_Retail_GeneralAgent') ||
            userChannel.request('hasCapability', 'Benefits_Retail_GeneralAgent(disabled)')));

        // User is HO or VaP, so the benefits links should be disabled
        this.model.set('disableBenefitsLinks',
            userChannel.request('hasCapability', 'Benefits_Retail_GeneralAgent(disabled)'));
    },

    onRender () {
        // If the countdown markup is present in the DOM, start the countdown
        if (this.ui.comingSoon.length) {
            this._startCountdown(this.ui.comingSoon[0], this.model.get('comingSoonEnd'));
        }
        if (this.ui.nowOpen.length) {
            this._startCountdown(this.ui.nowOpen[0], this.model.get('nowOpenEnd'));
        }
    },

    onBeforeDestroy () {
        for (let i=0; i<this.countdowns.length; i++) {
            window.clearInterval(this.countdowns[i]);
        }
    },

    _startCountdown (el, targetDateIso) {
        let _this = this;

        const millisPerSecond   = 1000;
        const millisPerMinute   = millisPerSecond * 60;
        const millisPerHour     = millisPerMinute * 60;
        const millisPerDay      = millisPerMinute * 60 * 24;
        const targetDate        = moment.tz(targetDateIso, config.defaultTimezone);
        const currentDate       = moment.tz(moment(), config.defaultTimezone);
        let totalMilliseconds   = targetDate.diff(currentDate);

        const intervalHandle = window.setInterval(() => {
            let remainingMilliseconds = totalMilliseconds;

            let remainingDays = Math.floor(remainingMilliseconds / millisPerDay);
            remainingMilliseconds = remainingMilliseconds % millisPerDay;

            let remainingHours = Math.floor(remainingMilliseconds / millisPerHour);
            remainingMilliseconds = remainingMilliseconds % millisPerHour;

            let remainingMinutes = Math.floor(remainingMilliseconds / millisPerMinute);
            remainingMilliseconds = remainingMilliseconds % millisPerMinute;

            let remainingSeconds = Math.floor(remainingMilliseconds / millisPerSecond);
            remainingMilliseconds = remainingMilliseconds % millisPerSecond;

            el.querySelector('.js-countdown-days').innerHTML = remainingDays;
            el.querySelector('.js-countdown-hours').innerHTML = remainingHours;
            el.querySelector('.js-countdown-minutes').innerHTML = remainingMinutes;
            el.querySelector('.js-countdown-seconds').innerHTML = remainingSeconds;

            totalMilliseconds = totalMilliseconds - millisPerSecond;

            // Don't let the countdown go into negative numbers
            if (totalMilliseconds < 0) {
                totalMilliseconds = 0;

                // Clear interval and re-render view to clear/show new banner
                window.clearInterval(intervalHandle);
                window.setTimeout(() => {
                    _this.render();
                }, 1000);
            }
        }, millisPerSecond);

        // Save a handle to this interval so it can be cleared later
        this.countdowns.push(intervalHandle);
    }
});

module.exports = EnrollmentBannerView;